import { Input, message, Image, Typography, Spin, Progress, Skeleton, Row, Col } from 'antd';
import { COLORS } from '../../themes/colors';
import { useState, useMemo, useEffect, useContext, memo, Fragment, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { IStream, IVideo } from '../../data/intefaces/stream.interface';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import { ReactComponent as IconUpload } from '../../assets/upload.svg';
import { ReactComponent as IconArrowDown } from '../../assets/arrow_down.svg';
import IconLightning from '../../assets/lightning-icon.svg';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { UserContext } from '../../data/userContext';

import './Videos.less';
import { StreamCard } from '../stream-card';
import { VideoPreview } from '../video-preview';
import { debounce } from 'lodash';
import { UploadFromComputerButton } from '../upload-from-computer-button';
import { ClipCard } from '../clip-card';
import { UploadModal } from '../upload-modal';
import { isYoutubeUrl, isTwitchUrl, isVimeoUrl, isGoogleDriveUrl, checkFileType, removeFileExtension, checkStreamIsAvailableToRender } from '../../utils';
import * as tus from 'tus-js-client';
import { SpikesGuide } from '../spikes-guide';
import { UploadSteps } from '../upload-steps';
import { UploadCard } from '../upload-card';
import { UploadRow } from '../upload-card/UploadRow';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { EStreamStatus } from '../../data/enums/stream-status.enum';
import { postAudit } from '../../services/audit.service';
import GameModal from '../phaser-game/GameComponent';
import Modal from 'react-modal';
import { IEditorTemplate } from '../../data/intefaces/streamer.interface';
import { YoutubeLimitModal } from '../youtube-limit-modal';
import { ErrorInfoModal } from '../error-info-modal';

const SAMPLE_UPLOAD_URL = 'https://drive.google.com/file/d/1j4lQs6jyHGVQACNtrb-jhD2ReYgmp5IQ/view?usp=sharing';


interface IUploadedVideoDetails {
  uploadedVideoUrl?: string | null;
  videoDuration?: number | null;
  videoTitle?: string | null;
  fileType?: 'video' | 'audio';
}

type UploadMode = 'transcribe' | 'highlights';

type Mode = 'personal' | 'youtube' | 'twitch' | 'vimeo' | 'google_drive';


export const Videos = memo(({ tutorialOpen = false, handleCloseTour, pageMode }: { tutorialOpen?: boolean, handleCloseTour?: () => void, pageMode: 'transcribe' | 'highlights' }) => {
  const POLLING_INTERVAL = 5000;
  const PAGE_SIZE = 21;

  const [{ data, error, loading }, refetch] = useAxios('/streamer/uploads');
  const streams = data as IStream[];
  const [currentPage, setCurrentPage] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [url, setUrl] = useState<string>('');
  const [urlForPreview, setUrlForPreview] = useState<string>('');
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
  const [disableMakeClips, setDisableMakeClips] = useState<boolean>(true);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [uploadedVideoDetails, setUploadedVideoDetails] = useState<IUploadedVideoDetails | null>(null);
  const [youtubeVideoDetails, setYoutubeVideoDetails] = useState<any>(null);
  const [mode, setMode] = useState<Mode | null>(null);
  const [progress, setProgress] = useState<number>(100);
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [showSpikesGuide, setShowSpikesGuide] = useState(false);
  const [showUploadStepsModal, setShowUploadStepsModal] = useState(false);
  const [selectedUploadCard, setSelectedUploadCard] = useState<null | string>(null);
  const [openedUploadingVideo, setOpenedUploadingVideo] = useState(false);
  const [openMaintenancePopup, setOpenMaintenancePopup] = useState(false);
  const [openYoutubeLimitModal, setOpenYoutubeLimitModal] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(true);
  const [showYTLimitationModal, setShowYTLimitationModal] = useState(false);
  const [showYTLimitationLabel, setShowYTLimitationLabel] = useState(false);
  const isDemoSeen = localStorage.getItem('videoPreviewDemoSeen');
  const isSpikesGuideSeen = localStorage.getItem('spikesGuideSeen');
  const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const isGoogleUser = !userContext?.user?.twitch_id;
  const usedMinutes = userContext?.user?.used_upload_minutes;
  const totalMinutes = userContext?.user?.total_upload_minutes;

  const indexOfLastItem = currentPage * PAGE_SIZE;
  const indexOfFirstItem = indexOfLastItem - PAGE_SIZE;

  const transcribeVideoLimit = userContext?.user?.permissions?.video_transcribe_limit ? userContext?.user?.permissions?.video_transcribe_limit : 2;

  const regex = /^(https?:\/\/)?(www\.)?(clips\.)?twitch\.tv\/.*/;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const debouncedSetUrlForPreview = debounce(setUrlForPreview, 500);

  const inputPlaceholder = isGoogleUser ? 'Insert Public URL / Youtube link / Google Drive link' : 'Insert Public URL / Youtube link / Google Drive link';
  // const inputPlaceholder = !isGoogleUser ? 'YouTube / Vimeo / Google Video URL' : 'YouTube Stream URL / Twitch Clip URL';

  useEffect(() => {
    if (error) {
      if (error?.response?.status === 403) {
        localStorage.removeItem('user');
        window.location.href = '/';
      }
    }
  }, [error]);

  useEffect(() => {
    refetch(); // Fetch on the first load
  }, []);

  useEffect(() => {
    const timer = setInterval(refetch, POLLING_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!isSpikesGuideSeen) {
      setShowSpikesGuide(true);
    }
  }, [isSpikesGuideSeen]);

  useEffect(() => {
    const homepageVideoUrl = localStorage.getItem('homepage-video-url');

    if (homepageVideoUrl) {
      setUrl(homepageVideoUrl);
    }
    localStorage.removeItem('homepage-video-url');
  }, []);


  // Automatically open card if video was just uploaded
  useEffect(() => {
    const filteredStreams = streams?.filter((stream) => stream.upload_mode === pageMode);
    if (filteredStreams && filteredStreams[0]) {
      if (filteredStreams[0].clips) {
        if (filteredStreams[0].clips.length === 0 && !selectedUploadCard && !openedUploadingVideo) {
          setSelectedUploadCard(filteredStreams[0].id);
          setOpenedUploadingVideo(true);
        }
      }
    }
  }, [streams]);

  useEffect(() => {
    const clipId = getLoadingClipId();

    if (clipId && streams) {
      const foundStream = streams.find(stream => stream.clips.find(clip => clip.id === clipId));
      if (foundStream) {
        setSelectedUploadCard(foundStream.id);
      }
    }
  }, []);

  const getLoadingClipId = () => {
    const storedData = localStorage.getItem('loadingClipId');
    return storedData ? JSON.parse(storedData) : null;
  };

  useEffect(() => {
    if (url && !isDemoSeen) {
      setDemoSeen();
    }
    debouncedSetUrlForPreview(url);
    if (!url) {
      setDisableMakeClips(true);
    } else if (isTwitchUrl(url)) {
      setDisableMakeClips(false);
      handleOpenStepsModal();
    } else if (isVimeoUrl(url)) {
      setDisableMakeClips(false);
      handleOpenStepsModal();
    } else if (isGoogleDriveUrl(url)) {
      setDisableMakeClips(false);
      handleOpenStepsModal();
    } else if (isYoutubeUrl(url)) {
      // setOpenMaintenancePopup(true);
      // clearUrlField();
      // return;
      handleOpenStepsModal();
    }
    if (url) {
      setLoadingPreview(true);
      handleShowUploadModal(true);
    }
  }, [url, isDemoSeen, youtubeVideoDetails]);

  const handleOpenStepsModal = () => {
    setShowUploadStepsModal(true);
  };

  const styles = useMemo(() => {
    return {
      resultsContainer: {
        maxWidth: 1384,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 50
      },
      main: {
        background: '#313131',
        fontSize: 14,
        height: 33,
        padding: '0 20px',
        borderRadius: 8,
        width: '100%',
        maxWidth: 383,
      },
      button: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 100,
        fontSize: 14,
        fontFamily: 'BrandonText',
        border: 2,
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      uploadButton: {
        letterSpacing: 'normal',
        left: 5,
        height: 34,
        width: 125,
        fontSize: 12,
        fontFamily: 'BrandonText',
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      searchButton: {
        letterSpacing: 'normal',
        left: 15,
        height: 34,
        width: 195,
        fontSize: 12,
        fontFamily: 'BrandonText',
        boxShadow: 'rgb(0, 239, 248) 0px 0px 10px'
      },
      makeClipsButtonWrapper: {
        height: 60,
        // background: COLORS.GRAY_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 7,
        borderRadius: '0 8px 8px 0'
      },
      makeClipsButtonStyle: {
        cursor: 'pointer',
        // backgroundColor: '#F1F1F1',
        borderRadius: 8,
        minHeight: 40,
        width: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 12px',
      },
      makeClipsButtonDisabled: {
        backgroundColor: COLORS.DISABLED,
        cursor: 'not-allowed'
      },
      uploadFromComputer: {
        cursor: 'pointer',
        backgroundColor: '#404040',
        borderRadius: 6,
        height: 56,
        width: 280,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      makeClipsText: {
        fontWeight: 600,
        color: COLORS.BLACK,
      },
      uploadFromComputerText: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#D9D9D9',
      },
      horizontalDivider: {
        height: 1.5,
        width: 40,
        backgroundColor: COLORS.GRAY_CONTROLS,
        borderRadius: 2,
        margin: '20px auto',
      },
      secondaryButtonStyle: {
        cursor: 'pointer',
        backgroundColor: '#404040',
        borderRadius: 6,
        height: 56,
        width: 230,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 14px',
      },
      secondaryButtonText: {
        fontSize: '20px',
        fontWeight: 400,
        color: '#D9D9D9',
        marginLeft: '12px',
      },
      progressContainer: {
        width: 200,
        margin: '0 auto',
        marginBottom: 10
      },
      topItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 562,
        margin: '0 auto',
        marginBottom: 16
      }
    } as const;
  }, []);

  const setDemoSeen = () => {
    localStorage.setItem('videoPreviewDemoSeen', 'true');
  };

  const handleDeleteClip = async (clipId: string) => {
    const response = await axios.put('/streamer/update-favorite-clip', {
      clip: clipId,
      is_removed_from_favorite: true
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    if (response?.status === 200) {
      refetch();
      messageApi.success('Clip removed!', 3);
    }
  };

  const handleClickMakeClipsButton = () => {
    if (!loadingPreview && (urlForPreview || uploadedVideoDetails)) {
      handleShowUploadModal(true);
      setShowUploadStepsModal(true);
    }
    else {
      messageApi && messageApi.info(`Please insert ${inputPlaceholder}`, 5);
    }
  };

  const handleMakeClips = async (userTemplate?: IEditorTemplate, autoZoomEnabled?: boolean, uploadedVideoUrl?: string | null, videoDuration?: number | null, videoTitle?: string | null, upload_mode?: UploadMode | null, contentMode?: Mode | null, clipLength?: number, stylePreset?: any, layoutType?: 'mobile' | 'desktop', fileType?: 'video' | 'audio', magicLookText?: string, enableFaceTracking?: boolean, language?: string | null, featureSliders?: any) => {
    if (disableMakeClips) return;
    if (usedMinutes && totalMinutes && (usedMinutes >= totalMinutes)) {
      navigate('/subscriptions', { state: { message: 'Uh-oh! You\'ve hit the maximum upload minutes limit.' } });
    } else {
      videoDuration = videoDuration == null ? 0 : videoDuration;
      videoTitle = videoTitle == null ? '' : videoTitle;
      const blurredBackgroundProp = userTemplate?.subtitles_settings.blurred_background;
      stylePreset['blurred_background'] = typeof blurredBackgroundProp === 'boolean' ? blurredBackgroundProp : true;
      const subtitlesEnabledProp = userTemplate?.subtitles_settings.subtitles_enabled;
      const subtitles_enabled = typeof subtitlesEnabledProp === 'boolean' ? subtitlesEnabledProp : true;
      const watermarkSliders = (featureSliders && featureSliders.length > 0) ? JSON.stringify(featureSliders.filter((slider: any) => slider.type === 'watermark')) : null;
      const payload: any = { platform_url: uploadedVideoUrl || url, platform: contentMode, duration: videoDuration, title: videoTitle, upload_mode: upload_mode, clip_length_request: clipLength || -1, style_properties: stylePreset, orientation: layoutType, upload_media_type: fileType, user_prompt: magicLookText || '', enable_face_tracking: Boolean(enableFaceTracking), user_language_choice: language, subtitles_enabled: subtitles_enabled, emojis_enabled: subtitles_enabled }

      if (watermarkSliders) {
        payload.features = watermarkSliders;
      }

      if (enableFaceTracking) {
        payload.is_auto_zoom = autoZoomEnabled;
      }

      const response = await axios.post('/streamer/upload', payload)
        .catch((error) => {
          if (error.response) {
            console.log('error', error.response);
            if (error.response?.data?.message && error.response?.data?.message === 'profanity_in_prompt') {
              messageApi.error('Profanity is not allowed in Magic Look', 5);
            } else {
              messageApi.error('Error uploading a video.', 5);
              postAudit({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                user_action: `Video upload failed. URL: ${uploadedVideoUrl}, Platform: ${contentMode}, Error: ${JSON.stringify(error)}`,
                user_id: userContext?.user?.id
              });
            }
          }

          if (contentMode === 'personal') {
            postAudit({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              user_action: `Error uploading a PERSONAL video. POST "/streamer/upload" failed. URL: ${uploadedVideoUrl}, Error: ${JSON.stringify(error)}`,
              user_id: userContext?.user?.id
            });
          } else {
            postAudit({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              user_action: `Error uploading a video. URL: ${uploadedVideoUrl}`,
              user_id: userContext?.user?.id
            });
          }
        });

      if (response) {
        messageApi.success('Clips uploading...', 5);
        userContext?.setUser(response?.data);
      }
      setUrl('');
      setDisableMakeClips(true);
    }
    setOpenUploadModal(false);
    setMode(null);
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
  };


  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const getYoutubeVideoDuration = (videoDetails: any) => {
    if (videoDetails) {
      if (videoDetails.contentDetails.videoDuration) {
        return videoDetails.contentDetails.videoDuration;
      } else {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = videoDetails.contentDetails.duration.match(regex);
        if (matches) {
          const hours = parseInt(matches[1]) || 0;
          const minutes = parseInt(matches[2]) || 0;
          const seconds = parseInt(matches[3]) || 0;
          const totalSeconds = hours * 3600 + minutes * 60 + seconds / 100;
          return totalSeconds;
        }
      }
    } else {
      return null;
    }
  };

  const handleShowUploadModal = (fromUrl = false) => {
    if (fromUrl && url) {
      const isYoutube = isYoutubeUrl(url);
      const isTwitch = isTwitchUrl(url);
      const isVimeo = isVimeoUrl(url);
      const isGoogleDrive = isGoogleDriveUrl(url); // Check for Google Drive URL

      if (isTwitch) {
        setMode('twitch');
        setUploadedVideoDetails({ uploadedVideoUrl: url, videoDuration: getYoutubeVideoDuration(youtubeVideoDetails), videoTitle: youtubeVideoDetails?.snippet?.title });
        // setOpenUploadModal(true);
      } else if (isYoutube) {
        setMode('youtube');
        setUploadedVideoDetails({ uploadedVideoUrl: url, videoDuration: getYoutubeVideoDuration(youtubeVideoDetails), videoTitle: youtubeVideoDetails?.snippet?.title });
        // setOpenUploadModal(true);
      } else if (isVimeo) {
        setMode('vimeo');
        setUploadedVideoDetails({ uploadedVideoUrl: url, videoDuration: getYoutubeVideoDuration(youtubeVideoDetails), videoTitle: youtubeVideoDetails?.snippet?.title });
        // setOpenUploadModal(true);
      } else if (isGoogleDrive) { // Handle Google Drive URL
        setMode('google_drive');
        setUploadedVideoDetails({ uploadedVideoUrl: url, videoDuration: getYoutubeVideoDuration(youtubeVideoDetails), videoTitle: youtubeVideoDetails?.snippet?.title });
        // setOpenUploadModal(true);
      } else if (!isYoutube && !isTwitch && !isVimeo && !isGoogleDrive) {
        messageApi.error('Invalid URL', 5);
        setMode(null);
        // setOpenUploadModal(false);
        setLoadingPreview(false);
        setUploadedVideoDetails(null);
        setShowUploadStepsModal(false);
      }
    }
    // else if (fromUrl && !url) {
    //   messageApi.error('Please insert URL', 5);
    // } else if (!fromUrl) {
    //   setOpenUploadModal(true);
    // }
  };


  const handleUploadFromComputerSuccess = (uploadedVideoUrl?: string | null, videoDuration?: number | null, videoTitle?: string | null, fileType?: 'video' | 'audio') => {
    setMode('personal');
    setDisableMakeClips(false);
    setUploadedVideoDetails({ uploadedVideoUrl, videoDuration, videoTitle, fileType });
    handleShowUploadModal();
    setShowUploadStepsModal(true);
  };

  const handleTranscribe = useCallback((userTemplate: IEditorTemplate, autoZoomEnabled?: boolean, clipLength?: number, stylePreset?: any, layoutType?: 'mobile' | 'desktop', fileType?: 'video' | 'audio', magicLookText?: string, enableFaceTracking?: boolean, language?: string | null, featureSliders?: any) => {
    // if (mode === 'personal' || mode === 'youtube') {
    handleMakeClips(userTemplate, autoZoomEnabled, uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, 'transcribe', mode, clipLength, stylePreset, layoutType, uploadedVideoDetails?.fileType || fileType, magicLookText, enableFaceTracking, language, featureSliders);
    // }
    return null;
  }, [uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, mode, disableMakeClips]);

  const handleExtractHighlights = useCallback((userTemplate: IEditorTemplate, autoZoomEnabled?: boolean, clipLength?: number, stylePreset?: any, layoutType?: 'mobile' | 'desktop', fileType?: 'video' | 'audio', magicLookText?: string, enableFaceTracking?: boolean, language?: string | null, featureSliders?: any) => {
    // if (mode === 'personal' || mode === 'youtube') {
    handleMakeClips(userTemplate, autoZoomEnabled, uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, 'highlights', mode, clipLength, stylePreset, layoutType, uploadedVideoDetails?.fileType || fileType, magicLookText, enableFaceTracking, language, featureSliders);
    // }
    return null;
  }, [uploadedVideoDetails?.uploadedVideoUrl, uploadedVideoDetails?.videoDuration, uploadedVideoDetails?.videoTitle, mode, disableMakeClips]);

  const getVideoDuration = () => {
    // if (mode === 'personal') return uploadedVideoDetails?.videoDuration;
    return uploadedVideoDetails?.videoDuration;
  };

  const getVideoTitle = () => {
    return uploadedVideoDetails?.videoTitle;
  };

  const handleMyClipsClick = () => {
    navigate(`/spikes/search-twitch-clips?username=${userContext?.user?.username}`);
  };

  const handleUploadFromTwitchClick = () => {
    navigate('/spikes/search-twitch-clips');
  };

  const showProgress = progress !== 100;
  const dragOverClass = isDragOver ? 'drag-over' : '';

  const handleFileSelect = (e: any) => {
    if (loadingFileUpload) return null;
    try {
      // Get the selected file from the input element
      const file = e.target.files[0];
      setLoadingFileUpload(true);

      /* Get video duration */
      if (file) {
        postAudit({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user_action: `IN PROGRESS: Personal upload to TUS server started. File name: ${file?.name || 'Not defined'}, File Details: ${JSON.stringify(file)}`,
          user_id: userContext?.user?.id
        });
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        const fileType = checkFileType(file?.type);

        videoElement.onloadedmetadata = () => {
          const videoDuration = videoElement.duration; // Duration in seconds
          videoElement.remove(); // Remove the temporary video element

          // if (videoDuration / 60 <= 30) {
          const videoTitle = removeFileExtension(file?.name);
          // Create a new tus upload
          const upload = new tus.Upload(file, {
            endpoint: process.env.REACT_APP_TUS_SERVER_URL,
            onBeforeRequest: function (req) {
              const xhr = req.getUnderlyingObject();
              xhr.withCredentials = true;
            },
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
              filename: file.name,
              filetype: file.type
            },
            onError: function (error: any) {
              setLoadingFileUpload(false);
              postAudit({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                user_action: `FAILED: Personal upload to TUS server. File name: ${file?.name || 'Not defined'}, Error: ${JSON.stringify(error)}, File Details: ${JSON.stringify(file)}`,
                user_id: userContext?.user?.id
              });
              console.log('Failed because: ' + error);
            },
            onProgress: function (bytesUploaded: number, bytesTotal: number) {
              setLoadingFileUpload(true);
              const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
              setProgress(Number(percentage));
            },
            onSuccess: function () {
              setLoadingFileUpload(false);
              if (upload.url) {
                setTimeout(() => {
                  handleUploadFromComputerSuccess(upload.url, videoDuration, videoTitle, fileType);
                  postAudit({
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    user_action: `SUCCESS: Personal upload to TUS finished. File name: ${file?.name || 'Not defined'}`,
                    user_id: userContext?.user?.id
                  });
                }, 100);
              }
            },
          });

          // Check if there are any previous uploads to continue.
          upload.findPreviousUploads().then(function (previousUploads: any) {
            // Found previous uploads so we select the first one.
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0]);
            }

            // Start the upload
            upload.start();
          });
        };
        videoElement.src = URL.createObjectURL(file); // Create a temporary URL for the video
      }
    } catch (error) {
      postAudit({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user_action: `FAILED: Personal upload to TUS server, File Details: ${JSON.stringify(file)}, Error: ${JSON.stringify(error)}`,
        user_id: userContext?.user?.id
      });
    } finally {
      setIsDragOver(false);
    }
  };

  const handleFileDragOver = (event: any) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleFileDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleFileSelect({ target: { files: event.dataTransfer.files } });
    setIsDragOver(false);
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleCloseSpikesGuide = () => {
    localStorage.setItem('spikesGuideSeen', 'true');
    setShowSpikesGuide(false);
  };

  const handleCloseStepsModal = useCallback(() => {
    setShowUploadStepsModal(false);
  }, []);

  const handleVideoDurationLimit = useCallback((customMessage?: string) => {
    handleCloseStepsModal();
    clearUrlField();
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
    messageApi.error(customMessage || `Duration limit for Add Subtitles is ${transcribeVideoLimit} minutes`, 5);
  }, []);

  const handleShowYoutubeLimit = useCallback(() => {
    handleCloseStepsModal();
    clearUrlField();
    setUploadedVideoDetails(null);
    setYoutubeVideoDetails(null);
    // setOpenYoutubeLimitModal(true);
    setShowYTLimitationLabel(true);
  }, []);

  const handleCloseYoutubeLimit = useCallback(() => {
    setShowYTLimitationLabel(false);
  }, []);

  const clearUrlField = useCallback(() => {
    setUrl('');
  }, []);

  const handleClickOnUploadCard = (uploadId: string) => {
    if (selectedUploadCard === uploadId) {
      setSelectedUploadCard(null); // Close
    } else {
      setSelectedUploadCard(uploadId);
    }
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions(prev => !prev);
  }

  // Function to split the array into chunks
  const chunkArray = (array: any, size: number) => {
    if (!array || array.length < 1) return [];
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const getClipsPerRow = () => {
    if (width > 1000) return 3;
    else if (width > 720) return 2;
    else return 1;
  };

  const chunkedStreams = chunkArray(streams?.filter((stream, index) => stream.upload_mode === pageMode), getClipsPerRow()); // Split streams into separate array with 3 items in each of it

  const handleCollapseClips = () => {
    setSelectedUploadCard(null);
  };

  const handleCloseMaintenancePopup = () => {
    setOpenMaintenancePopup(false);
  }

  const handleCloseYoutubeLimitModal = () => {
    setOpenYoutubeLimitModal(false);
  }

  const handleSampleUpload = () => {
    setUrl(SAMPLE_UPLOAD_URL);
  }

  const filteredStreams = streams?.filter((stream, index) => stream.upload_mode === pageMode);

  const hasLoadingProjects = filteredStreams && Boolean(filteredStreams.find(stream => !checkStreamIsAvailableToRender(stream)));

  return (
    <>
      <div style={styles.topItemsContainer}>
        <Modal
          isOpen={openMaintenancePopup}
          className="Modal"
          overlayClassName="Overlay"
          shouldCloseOnOverlayClick={true}
          onRequestClose={handleCloseMaintenancePopup}
        >
          <div style={{ width: 470 }} className="glass-modal">
            <span onClick={handleCloseMaintenancePopup} className="icon-close">X</span>
            <h1>🔨 Youtube maintenance</h1>
            <p>Youtube support is currently under maintenance. In the meantime, please try uploading your video directly from your computer or via Google Drive.</p>

            <div className="buttons center">
              <div style={{ minWidth: 170 }} onClick={handleCloseMaintenancePopup} className="confirm-btn">
                <span>Got it!</span>
              </div>
            </div>

          </div>
        </Modal>
        <YoutubeLimitModal open={openYoutubeLimitModal} onClose={handleCloseYoutubeLimitModal} />

        <div style={{ width: '100%' }}>
          <div
            onDragOver={handleFileDragOver}
            onDrop={handleFileDrop}
            className={`file-upload-wrapper ${dragOverClass}`}
            onDragLeave={handleDragLeave}
          >
            <input
              type="file"
              accept="audio/*,video/*"
              id="file-input"
              className="file-input"
              onChange={handleFileSelect}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-input" className="file-input-label">
              {loadingFileUpload ? `Uploading your file (${progress}%)` : 'Choose a video or drag it here'}

              <div id='make-clips-button' className='opacity-hover white-gradient' style={{ ...styles.makeClipsButtonStyle }}>
                {/* {loadingPreview ? <Spin indicator={<LoadingOutlined style={{ color: '#000', fontSize: 22 }} spin />} style={{ marginRight: 3, marginLeft: -10 }} /> : <Image src={IconLightning} preview={false} />} */}

                <Typography className='make-clips-text' style={styles.makeClipsText}>{loadingPreview ? 'Loading...' : 'Make Clips'}</Typography>

              </div>
            </label>
          </div>

          {/* <div onClick={toggleMoreOptions} className={`more-options ${showMoreOptions && 'active'}`}>
            <span>More Options</span>
            <IconArrowDown />
          </div> */}
        </div>


        <div style={{ marginTop: 12 }} className={`more-options-block ${showMoreOptions ? 'expanded' : ''}`}>
          <div className="or-label">OR</div>
          <div style={{ color: 'black', display: 'flex', justifyContent: 'center', width: '100%' }}>
            {contextHolder}
            <Input id='main-input' placeholder={inputPlaceholder} disabled={loadingFileUpload} value={url} style={styles.main} onChange={(e) => setUrl(e.target.value)} />
          </div>

          {showYTLimitationLabel && <div className="yt-limit-label">For free users, YouTube uploads are capped at 15 minutes.</div>}

        </div>
        <div onClick={handleSampleUpload} className="sample-upload-link">Click here to try a sample upload</div>


        <ErrorInfoModal open={showYTLimitationModal} onClose={() => setShowYTLimitationModal(false)} platform='youtube' customTitle='How to download your YouTube videos' withoutDescription />



        {showUploadStepsModal &&
          <UploadSteps
            isOpen={showUploadStepsModal}
            url={urlForPreview}
            setLoading={setLoadingPreview}
            setDisableMakeClips={setDisableMakeClips}
            setYoutubeVideoDetails={setYoutubeVideoDetails}
            handleExtractHighlights={handleExtractHighlights}
            handleTranscribe={handleTranscribe}
            handleCloseModal={handleCloseStepsModal}
            mode={pageMode}
            uploadedVideoDetails={uploadedVideoDetails}
            clearUrlField={clearUrlField}
            handleVideoDurationLimit={handleVideoDurationLimit}
            handleShowYoutubeLimit={handleShowYoutubeLimit}
            handleCloseYoutubeLimit={handleCloseYoutubeLimit}
          />

        }

        {showSpikesGuide && <SpikesGuide handleClose={handleCloseSpikesGuide} />}
      </div>


      <div style={styles.resultsContainer}>
        {/* {hasLoadingProjects && <div className='game-card-wrapper'><GameModal /></div>} */}

        {filteredStreams && hasLoadingProjects &&
          <div className='loading-projects-grid'>
            {filteredStreams?.length > 0 && filteredStreams.map((stream: any, i: number) => !checkStreamIsAvailableToRender(stream) && (
              <>
                <UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isYoutubeCard loadingCard />
              </>
            ))}
          </div>
        }
        <div className='projects-container'>

          <div className='title'>Latest Projects</div>
          <Row gutter={[24, 24]}>
            {filteredStreams ?
              <>
                {filteredStreams?.length > 0 && filteredStreams?.map((stream: any, i) => checkStreamIsAvailableToRender(stream) && (
                  <Col key={stream.id} xs={24} md={12} lg={8}><UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isYoutubeCard /></Col>
                ))}
              </>
              :
              <>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
                <Col xs={24} md={12} lg={8}><Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} /></Col>
              </>
            }
          </Row>
        </div>
        {/* {chunkedStreams ?
            <>
              {chunkedStreams?.length > 0 &&
                chunkedStreams.map(streamsArray =>
                  streamsArray?.length > 0 &&
                  <UploadRow stream={streamsArray.find((stream: IStream) => stream.id === selectedUploadCard)} refetch={refetch} handleCollapseClips={handleCollapseClips} isTranscribePage={pageMode === 'transcribe'}>
                    {(streamsArray?.map((stream: IStream, i: number) => stream.upload_mode === pageMode && (
                      <Fragment key={stream.id}>
                        <div onClick={() => handleClickOnUploadCard(stream.id)}>
                          <UploadCard selectedUploadCard={selectedUploadCard} key={stream.id} stream={stream} index={i} refetch={refetch} isYoutubeCard />
                        </div>
                      </Fragment>
                    )))}
                  </UploadRow>
                )
              }
            </>
            :
            <>
              <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
              <div style={styles.horizontalDivider} />
              <Skeleton className='streams-skeleton' active title={false} paragraph={{ rows: 1 }} />
            </>
          } */}
      </div>
    </>
  );
});

Videos.displayName = 'Videos';

export default Videos;