import { useContext, useEffect } from 'react';
import { Navigate, Routes, useLocation } from 'react-router';
import { Route, useSearchParams } from 'react-router-dom';
import { UserContext } from '../data/userContext';
import { LandingPage } from './Landing';
import { LoginPage } from './Login';
import { VerifyEmailPage } from './VerifyEmail';
import { SpikesPage } from './Spikes';
import { PrivacyPage } from './Privacy';
import { TermsPage } from './Terms';
import { EditorPage } from './Editor';
import { HomePage } from './Home';
import { AboutPage } from './About';
import { Dashboard } from './Dashboard/Index';
import { LiveStreamersPage } from './LiveStreamers';
import { GameDevelopersPage } from './GameDevelopers';
import { MarketingExpertsPage } from './MarketingExperts';
import { SubscriptionPage } from './Subscriptions/Subscriptions.page';
import { ReferralPage } from './ReferralPage/Index';
import { LoyaltyProgram } from './LoyaltyProgram';
import { ThankYou } from './ThankYou';
import { ProjectPage } from './ProjectPage';
import * as Sentry from '@sentry/react';
import { OnboardingPage } from './OnboardingPage';
import { SocialAnalyticsPage } from './SocialAnalytics';
import Tutorial from './Tutorial/Tutorial.page';
import { ApiDashboard } from './ApiDashboard/ApiDashboard.page';
import { SignUpPage } from './SignUp';
import { ResetPasswordPage } from './ResetPassword';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext?.user) {
      Sentry.setUser({ id: userContext.user?.id });
      Sentry.setTag('userId', userContext.user?.id);
    }
  }, [userContext]);

  const location = useLocation();

  if (!(userContext?.user?.in_waitlist === false)) {
    // if user is on waitlist/not logged skip & redirect
    return <Navigate to="/affiliate-program" state={{ from: location }} />;
  }

  return children;
};
const RequireRegister = ({ children }: { children: JSX.Element }) => {
  const userContext = useContext(UserContext);

  const location = useLocation();
  // if user is not logged in redirect skip
  if (!userContext?.user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export const Router = () => {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { platformStreamId, clip, preview }: any = state || {
    platformStreamId: null,
    clip: null,
    preview: false,
  };

  useEffect(() => {
    const videoUrl = searchParams.get('videoUrl');

    if (videoUrl) {
      localStorage.setItem('homepage-video-url', videoUrl);
    }
  }, []);

  return (
    <Routes>
      <Route path="*" element={<LoginPage />} />
      <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
      <Route path="/account-deleted" element={<LoginPage />} />
      <Route path="/set-password/:token" element={<ResetPasswordPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/login" element={<HomePage />} />
      {/* <Route path="/login" element={<LoginPage />} />
      <Route path="/login/not-on-list" element={<LoginPage />} />
      <Route path="/login/:user" element={<LoginPage />} /> */}
      <Route
        path="/spikes"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/onboarding"
        element={
          <RequireAuth>
            <OnboardingPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/welcome"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/tip"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/edit"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/settings"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/authorization/tiktok"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/delete-account"
        element={
          <RequireRegister>
            <SpikesPage />
          </RequireRegister>
        }
      />
      <Route
        path="/spikes/account-deleted"
        element={
          <RequireRegister>
            <SpikesPage />
          </RequireRegister>
        }
      />
      <Route
        path="/spikes/cancel-subscription"
        element={
          <RequireRegister>
            <SpikesPage />
          </RequireRegister>
        }
      />
      <Route
        path="/spikes/subscription-cancelled"
        element={
          <RequireRegister>
            <SpikesPage />
          </RequireRegister>
        }
      />
      {/* <Route
        path="/spikes/subscriptions"
        element={
          <RequireRegister>
            <SpikesPage defaultTabIndex={0} />
          </RequireRegister>
        }
      /> */}
      <Route
        path="/spikes/search-twitch-clips"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/search-twitch-clips"
        element={
          <RequireAuth>
            <SpikesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/streams"
        element={
          <RequireAuth>
            <SpikesPage defaultTabIndex={0} />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/foryou"
        element={
          <RequireAuth>
            <SpikesPage defaultTabIndex={1} />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/uploads"
        element={
          <RequireAuth>
            <SpikesPage defaultTabIndex={2} />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/videos"
        element={
          <RequireAuth>
            <SpikesPage defaultTabIndex={2} />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/transcribe"
        element={
          <RequireAuth>
            <SpikesPage defaultTabIndex={2} />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/twitch-clips"
        element={
          <RequireAuth>
            <SpikesPage defaultTabIndex={3} />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/project"
        element={
          <RequireAuth>
            <ProjectPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/social"
        element={
          <RequireAuth>
            <SocialAnalyticsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/spikes/loyalty-program"
        element={
          <RequireAuth>
            <LoyaltyProgram />
          </RequireAuth>
        }
      />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route
        path="/edit"
        element={
          <RequireAuth>
            <EditorPage
              platformStreamId={platformStreamId || ''}
              preview={preview}
              clip={clip}
            />
          </RequireAuth>
        }
      />
      <Route
        path="/subscriptions"
        element={<SubscriptionPage />}
      />
      <Route
        path="/subscriptions/success"
        element={<SubscriptionPage />}
      />
      <Route
        path="/subscriptions/failure"
        element={<SubscriptionPage />}
      />
      <Route
        path="/affiliate-program"
        element={
          <RequireRegister>
            <ReferralPage />
          </RequireRegister>
        }
      />
      <Route
        path="/dashboard"
        element={
          <RequireRegister>
            <Dashboard />
          </RequireRegister>
        }
      />
      <Route
        path="/api"
        element={
          <RequireRegister>
            <ApiDashboard />
          </RequireRegister>
        }
      />
      <Route
        path="/tutorial"
        element={
          <RequireRegister>
            <Tutorial />
          </RequireRegister>
        }
      />
      <Route path="/home" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/live-streamers" element={<LiveStreamersPage />} />
      <Route path="/game-developers" element={<GameDevelopersPage />} />
      <Route path="/marketing-experts" element={<MarketingExpertsPage />} />
      <Route path="/thank-you" element={<ThankYou />} />
    </Routes>
  );
};
